const KEY = suf => `forwoodsafety_map-report_company-${suf}`;

/*
 * Allows certain variables to be saved in localstorage for an improved UX.
 * It listens for something to be set with a redux action and then copies
 * it to localstorage. Then on AUTH_SET (basically means on init), it
 * triggers the same action it listened for with the same value.
 *
 * This could probably do with being refactored, especially if another
 * variable gets added to make it generic.
 */
export default store => next => action => {
  next(action);

  if (action.type === 'AUTH_SET') {
    const companyId = window.localStorage.getItem(KEY('id'));
    const companyName = window.localStorage.getItem(KEY('name'));
    if (companyId) {
      store.dispatch({type: 'SET_COMPANY', companyId, companyName});
    }
  }

  if (action.type === 'MINI_MAP_INIT') {
    store.dispatch({ type: "MINI_AUTH_SET", payload: {} });
  }

  if (action.type === 'AUTH_SET' || action.type === 'MINI_MAP_INIT') {
    const locale = window.localStorage.getItem(KEY('locale'));
    if (locale) {
      store.dispatch({type: 'LANGUAGE_SET', locale});
    }
  } else if (action.type === 'SET_COMPANY') {
    window.localStorage.setItem(KEY('id'), store.getState().map.companyId);
    window.localStorage.setItem(KEY('name'), store.getState().map.companyName);
  } else if (action.type === 'LANGUAGE_SET') {
    window.localStorage.setItem(KEY('locale'), action.locale);
  }
};
